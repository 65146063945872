:root {
  --bg: #f3f8fc;
  --primary: #455dee;
  --primary-dark: #a1b9ef;
  --primary-darkest: #2a41cd;
  --primary-light: #e2ebff;
  --white: #ffffff;
  --text: #373a50;
  --textDark: #101013;
  --gray: #7c838b;
  --disabled: #8d949d;
  --disabled-text: #9eabbe;
  --disabled-button: #e0e5ee;
  --swipe: #d4d8e3;
  --border: #e5e7ee;
  --icon: #c9dae8;
  --success: #56d390;
  --success-bg: #e9fcf2;
  --error: #f3665d;
  --error-bg: #fff5f4;

  --shadowSm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadowMd: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadowLg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadowXl: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --shadow2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --shadowInner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.pintura-editor {
  --color-background: 255, 255, 255;
  --color-foreground: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  .pintura-editor {
      --color-background: 0, 0, 0;
      --color-foreground: 255, 255, 255;
  }
}

.mask-box {
  border-radius: 10px;
  position: relative;
  background: #E2EBFF;
}
.mask-box .mask {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background: #455DEE;
  position: absolute;
  transition: all 0.5s ease;
}
.mask-box .MuiButton-root {
  border-radius: 10px;
  width: 100px;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
}
.mask-box .MuiButton-root:hover {
  opacity: 0.8;
}
