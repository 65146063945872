:root {
  --bg: #f3f8fc;
  --primary: #455dee;
  --primary-dark: #a1b9ef;
  --primary-darkest: #2a41cd;
  --primary-light: #e2ebff;
  --white: #ffffff;
  --text: #373a50;
  --textDark: #101013;
  --gray: #7c838b;
  --disabled: #8d949d;
  --disabled-text: #9eabbe;
  --disabled-button: #e0e5ee;
  --swipe: #d4d8e3;
  --border: #e5e7ee;
  --icon: #c9dae8;
  --success: #56d390;
  --success-bg: #e9fcf2;
  --error: #f3665d;
  --error-bg: #fff5f4;

  --shadowSm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadowMd: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadowLg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadowXl: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --shadow2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --shadowInner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.faq_main {
  padding-top: 64px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  padding-bottom: 20px;
}

.faq_main__Heading {
  margin-bottom: 32px;
}

.faq_acc__Style {
  margin-top: 70px;
  margin-bottom: 70px;
}

.faq_typo__Style {
  padding-bottom: 20px;
}
.faq__card {
  border: 1px solid var(--border);
}
.faq__card:hover::after {
  content: "";
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 2px solid transparent;
  border-radius: 16px;
  position: absolute;
  background: linear-gradient(
        var(--quick-links-hover-bg, var(--bg)),
        var(--quick-links-hover-bg, var(--bg))
      )
      padding-box,
    linear-gradient(304.2deg, #455dee 16.52%, #6999f5 42.76%, #85e1f5 87.79%)
      border-box;
}
.faq_link__Style {
  text-decoration: none;
}

.faq_link__Style:hover {
  cursor: pointer;
}

.faq_icon__Style:hover {
  background-color: white;
}

.faq_icon__Style {
  padding-top: 7px;
  padding-left: 7px;
}

.faq_desc__Style {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
  margin-top: 16px;
  font-size: 18px;
  color: #777777;
}

.faq_logo__Style > img {
  /* object-fit: contain;
    height: 36px;
    width: auto; */
  margin: 11px 18px 12px;
}

.faq_head__Style {
  border-bottom: 0.1px solid lightgray;
  width: 100%;
  top: 0;
  min-height: 64px;
}
