.watermarked {
  position: relative;
  overflow: hidden;
}

.watermarked img {
  width: 100%;
}

.watermarked::before {
  position: absolute;
  top: -50%;
  left: -50%;
  display: block;
  width: 200%;
  height: 200%;

  transform: rotate(-45deg);
  content: attr(data-watermark);

  opacity: 0.8;
  line-height: 3em;
  letter-spacing: 2px;
  font-size: 18px;
  z-index: 5;
  color: rgba(0, 0, 0, 0.3);
}
